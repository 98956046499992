$orange:#D87C2B;
$red:#782727;
$green:#27783e;

$light-red:#bb3535;
$light-green:#617e4e;
$light-blue:#1f8093;
$light-brown:#8d573f;
$light-yellow:#df9434;

$grey:#666;
$dark-grey:#333;
$basic-dark:#333;
$light-grey:#CCC;

$yellow:#EDDB55;

$admin-red: $light-red;
$offset: 10px;
$margin: 20px;
$indent: 0;


@font-face {
  font-family: "Oswald Bold";
  src: url("/fonts/oswald-variable.woff2") format("woff2"), url("/fonts/oswald-variable.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: 'OpenSans';
  src: url("/fonts/OpenSans-Regular.woff2") format("woff2"), url("/fonts/OpenSans-Regular.woff") format("woff");
  font-display: swap;
}

$title: "Oswald Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
$content: "OpenSans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
