@function luma($color) {
  $r: red($color);
  $g: green($color);
  $b: blue($color);

  @return 0.299 * $r + 0.587 * $g + 0.114 * $b;
}

@function contrast-color($color, $dark: #222, $light: #fff, $threshold: 130) {
  @return if(luma($color) < $threshold, $light, $dark);
}

@mixin flex_or_block($row: "row", $jc: "flex-start", $ai: "flex-start", $wrap: "nowrap", $grow: '1', $basis: 'auto') {
  @supports not ((display: -webkit-flex) or (display: -moz-flex) or (display: flex)) {
    display: block;

    @if $row == "row" {
      float: left;

      &:after {
        content: '';
        height: 0;
        display: block;
        clear: both;
        font-size: 1px;
        line-height: 0;
      }
    }
  }

  @supports (display: -webkit-flex) or (display: -moz-flex) or (display: flex) {
    display: flex;
    flex: #{$grow} 0 #{$basis};
    flex-direction: #{$row};
    justify-content: #{$jc};
    align-items: #{$ai};
    flex-wrap: #{$wrap};
  }
}
