.marg-no{
  margin:0;
}
.marg-lr{
  margin:0 10px;
}
.padd-no{
  padding:0;
}
.padd-lr{
  padding:0 10px;
}
.nonpadd-l{
  padding-left:0;
}
.nonpadd-r{
  padding-right:0;
}
.nonpadd-t{
  padding-top:0;
}
.nonpadd-b{
  padding-bottom:0;
}
.nonpadd-lr{
  padding-left:0;
  padding-right:0;
}

.rel{
  position:relative;
}

.clearfloat, .clfl:after{
  content:'';
  height:0;
  display:block;
  clear:both;
  font-size:1px;
  line-height:0;
}
