@import "variables.scss";
@import "common_mixins";

.flash {
  height: 48px;

  @include flex_or_block;

  position: relative;
  box-sizing: border-box;
  border-top: 1px solid #CCC;
  transition: left 0.5s ease, right 0.5s ease;
  background: #e6e6e6;
}

.flash-fix {
  width: 100%;
  height: 66px;
  position: fixed;
  bottom: 0;
}

.flash-close {
  width: 47px;
  height: 47px;
  display: block;
  position: relative;
  cursor: pointer;
  background: #FFF;

  &:before, &:after {
    content: '';
    width: 1px;
    height: 27px;
    display: block;
    position: absolute;
    top: 10px;
    left: 23px;
    transition: all 0.2s ease;
    background: #999;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  &:hover {
    &:before, &:after {
      background: #000;
    }
  }
}

.flash-ctn {
  padding: 12px 67px 12px 20px;
  height: 21px;
  font-size: 18px;
  line-height: 23px;
  position: relative;
  flex-grow: 999;
}

.flash-notice {
  color: $light-green;
}

.flash-alert {
  color: $light-red;
}

.flash-hide {
  animation-duration: 1s;
  animation-name: flash_hide;
}

@keyframes flash_hide {
  to {
    transform: translateY(100px);
  }
}
