@import "variables.scss";
@import "normalize.scss";

.body-main {
  min-width: 320px;
  min-height: 100vh;
  position: relative;
  text-align: center;
  font-family: $content;
  margin: 0;
}

.login-form {
  width: 400px;
  max-width: calc(100% - 20px);
  display: inline-block;
  box-sizing: border-box;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  text-align: left;
  vertical-align: middle;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2), 5px 5px 5px 0 rgba(0, 0, 0, 0.2);

  form {
    padding: 30px 20px;
    background: #FFF;
  }
}

.login-head {
  background: $red;
}

.logo-main {
  padding: 16px 10px 20px 20px;
  display: inline-block;
  font-family: $title;
  font-size: 40px;
  line-height: 40px;
  vertical-align: text-top;
  color: #FFF;
  transition: all 0.5s ease;

  &:hover {
    color: rgba(255, 255, 255, 0.8);
  }
}

.login-title {
  display: block;
  font-size: 30px;
  margin-bottom: 30px;
  line-height: 1em;
  font-weight: 300;
}

.login-input {
  width: 100%;
  height: 50px;
  display: block;
  padding: 10px;
  margin: 0 0 20px 0;
  border: 1px solid $grey;
  box-sizing: border-box;
  transition: all 0.2s ease;

  &:hover {
    border-color: $green;
  }

  &:focus {
    border-color: $orange;
  }
}

.login-checkbox {
  display: inline-block;
  vertical-align: middle;
  border: 1px solid $grey;

  input[type=checkbox] {
    display: none;
  }
}

.login-checkbox-span {
  width: 14px;
  height: 14px;
  display: block;
  cursor: pointer;
  transition: background-color 150ms ease-in;
  position: relative;
  background: #FFF;

  &:before, &:after {
    content: "";
    width: 2px;
    display: none;
    position: absolute;
  }
}

.login-checkbox input[type=checkbox]:hover + .login-checkbox-span {
  background: #CCC;
}

.login-checkbox input[type=checkbox]:checked + .login-checkbox-span {
  &:before {
    display: block;
    height: 6px;
    top: 5px;
    left: 4px;
    transform: rotate(-35deg);
    background: $green;
  }

  &:after {
    display: block;
    height: 17px;
    top: -4px;
    left: 11px;
    transform: rotate(45deg);
    background: $green;
  }
}

.login-checkbox input[type=checkbox]:checked:hover + .login-checkbox-span {
  background: #FFF;

  &:before {
    display: block;
    height: 12px;
    top: 1px;
    left: 6px;
    transform: rotate(-45deg);
    background: $orange;
  }

  &:after {
    display: block;
    height: 12px;
    top: 1px;
    left: 6px;
    transform: rotate(45deg);
    background: $orange;
  }
}

.login-label {
  font-size: 14px;
  vertical-align: middle;
  margin-left: 5px;
}

.log-in {
  display: inline-block;
  margin-top: 30px;
  padding: 9px 15px 11px 15px;
  border: 1px solid $green;
  border-radius: 5px;
  color: #FFF;
  font-size: 18px;
  font-weight: 300;
  cursor: pointer;
  transition: all 0.2s ease;
  background: $green;

  &:hover {
    border-color: $orange;
    background: $orange;
  }

  &:active {
    color: $green;
    background: #FFF;
    transition: all 0.1s ease;
  }
}

.forgot {
  display: inline-block;
  margin-left: 10px;
  margin-top: -2px;
  font-size: 14px;
  line-height: 16px;
  color: $green;
  border-bottom: 1px solid transparent;
  transition: all 0.2s ease;
  text-decoration: none;

  &:hover {
    border-color: $orange;
    color: $orange;
  }
}

section {
  padding: 10px;
  box-sizing: border-box;
  position: relative;
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

@import "flashes.scss";
@import "important.scss";
